import ckeditorconfig from "@/libs/ckeditorconfig";
export default [
  {
    key: "bannerId",
    initKey: "banner",
    label: "field.banner",
    type: "async-single-image",
    path: "tenant",
    pw: 2000,
    ph: 768,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,

  },
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "required|max:100",
    type: "text"
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    type: "text"
  },
  {
    key: "link",
    label: "field.link",
    type: "text"
  },

  {
    label: "breadcrumb.englishTenantContent",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "titleEn",
    label: "field.titleEn",
    rules: "required",
    type: "text",
    cols: 6,
  },
  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },

  {
    key: "contactInformationEn",
    label: "field.contactInformationEn",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },

  {
    key: "metaTitleEn",
    label: "field.metaTitleEn",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionEn",
    label: "field.metaDescriptionEn",
    type: "textarea",
    cols: 12
  },


  {
    label: "breadcrumb.khmerTenantContent",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "titleKm",
    label: "field.titleKm",
    rules: "required",
    type: "text",
    cols: 6,
  },
  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },
  {
    key: "contactInformationKm",
    label: "field.contactInformationKm",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },
  {
    key: "metaTitleKm",
    label: "field.metaTitleKm",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionKm",
    label: "field.metaDescriptionKm",
    type: "textarea",
    cols: 12
  },

  {
    label: "breadcrumb.tenantFeature",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key:"tenantFeatures",
    hideLabel:true,
    type: "slot",
    cols:12,
  },
  {
    label: "breadcrumb.tenantGallery",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key:"tenantGalleries",
    hideLabel:true,
    type: "slot",
    cols:12,
  },

  {
    key: "isEnable",
    label: "field.active",
    type: "checkbox",
    cols: "auto",
  },
]
